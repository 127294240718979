import AddFavourite from "../../utility/js/addFav";
import EncryptUtility from "../../utility/js/encrypt-utility";
import breadcrumbComp from "../../common/breadcrumb-comp";
import UsbService from "./usb-services.js";
import Utility from "../../../shared/utility";
import DisplayTextData from "../../common/display-text-data.vue";
export default {
  name: "bulkShipping",
  data: () => ({
    userId: EncryptUtility.localStorageDecrypt("userID"),
    backSlash: true,
    mainPage: "",
    subPage: "",
    pageUrl: "",
    soLotId: "",
    idSelected:'',
    filterValue:"",
    active: true,
    showAdd: false,
    refresh: true,
    favouriteList: [],
    bulkListDataList: [],
    cardsSubArray:[],
    complete:0,
    canceled:0,
    readyToShip: 0,
    lotBuilding: 0,
    backOrdered: 0,
    searchFilter: "",
    totalRecords: 0,
    inProcess: 0,
    excelName: "",
    status: "",
    bulkOrderDialog: false,
    showTrackingSection: false,
    trackNum: "",
    required: [(v) => !!v || "Field is required"],
    tableHeader: [
      { text: "Project", align: "start", value: "project", class: "primary customwhite--text" },
      { text: "Lot #", value: "lotno", class: "primary customwhite--text" },
      { text: "Request #", value: "req_number", class: "primary customwhite--text" },
      { text: "Lot Status", value: "so_lot_status", class: "primary customwhite--text" },
      { text: "Date", value: "createdate", class: "primary customwhite--text" },
      { text: "Lot Total", value: "lot_total", class: "primary customwhite--text" },
      { text: "Request Received", value: "dropped_total", class: "primary customwhite--text" },
      { text: "Allocated", value: "allocated_total", class: "primary customwhite--text" },
      { text: "Staged", value: "staged_total", class: "primary customwhite--text" },
      { text: "Picked", value: "picked_total", class: "primary customwhite--text" },
      { text: "In Bulk Queue", value: "queued_total", class: "primary customwhite--text" },
      { text: "Actions", value: "actions", class: "primary customwhite--text", sortable: false },
    ],
    bulkListData: [],
    detailsTableData: [],
    orderDetailsArray: [],
    cardsArray: [],
    detailsTableHeader: [
      { text: "Order No#", align: "start", value: "sono", class: "primary customwhite--text" },
      { text: "RITM", value: "ritm", class: "primary customwhite--text" },
      { text: "Created", value: "created_date", class: "primary customwhite--text" },
      { text: "SO Status", value: "so_status", class: "primary customwhite--text" },
      { text: "DOA", value: "isDOA", class: "primary customwhite--text" },
      { text: "Ordered Qty", value: "ordered_qty", class: "primary customwhite--text" },
    ],
  }),
  mounted() {
 
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.getDashboardData();
    this.getBulkData();
    this.timer = setInterval(() => {
      this.getDashboardData();
      this.getBulkData();
    }, 60000);
  },
  methods: {
    //Reset Function
    resetFunction() {
      this.searchFilter = "";
      this.getDashboardData();
      this.getBulkData();
    },
    //Favorite icon
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    async getDashboardData() {      
      let dashboardData = await UsbService.getDashboardData("get", this.userId);
      this.readyToShip = dashboardData?.tiles[0]?.ready_to_ship;
      this.lotBuilding = dashboardData?.tiles[0]?.lot_building;
      this.backOrdered = dashboardData?.tiles[0]?.backorder;
      this.inProcess = dashboardData?.tiles[0]?.in_process;
      this.canceled= dashboardData?.tiles[0]?.cancelled;
      this.complete= dashboardData?.tiles[0]?.complete;
      this.cardsSubArray=[
        { id: 5, icon: " mdi-check-circle-outline", title: "Complete", data: this.complete, background: "",iconColor:"success"  },
        { id: 6, icon: "mdi mdi-close-circle-outline", title: "Canceled", data: this.canceled, background: "",iconColor:"error"  }
      ]
      this.cardsArray = [
        { id: 1, icon: "mdi mdi-truck", title: "Ready To Ship", data: this.readyToShip, background: "success", iconColor: "" },
        { id: 2, icon: "mdi mdi-wrench-outline", title: "Lot Building", data: this.lotBuilding, background: "", iconColor: "secondary" },
        { id: 3, icon: "mdi mdi-timer-sand", title: "Back Order", data: this.backOrdered, background: "", iconColor: "error" },
        { id: 4, icon: "mdi mdi-cog-outline", title: "In-Process", data: this.inProcess, background: "", iconColor: "primary" },   
      ];
    },
    async getBulkData() {      
      if(this.status){
        if(this.status=="Canceled"){
          let respData = await UsbService.getShippingData("get", this.userId,2);
          respData?.forEach((element) => {
            element.createdate = Utility.convertESTToLocal(element.createdate)});
          this.bulkListDataList = respData;
        }
          else if(this.status=="Complete"){
          let respData = await UsbService.getShippingData("get", this.userId,1);    
          respData?.forEach((element) => {
            element.createdate = Utility.convertESTToLocal(element.createdate)});
          this.bulkListDataList = respData;
          }
          else if (this.status=="Ready To Ship")
            {
              let respData = await UsbService.getShippingData("get", this.userId,0);
              respData?.forEach((element) => {
                element.createdate = Utility.convertESTToLocal(element.createdate);
              });
              this.status="";
              this.bulkListData = respData;
              this.bulkListDataList = this.bulkListData;
            }
        
      }
      else{        
      let respData = await UsbService.getShippingData("get", this.userId,0);
      respData?.forEach((element) => {
        element.createdate = Utility.convertESTToLocal(element.createdate);
      });
      this.bulkListData = respData;
      this.bulkListDataList = this.bulkListData;
    }
  
      this.totalRecords = this.bulkListDataList.length;
    },
   async onClickCards(item) {
      this.status = item.title;
      this.filterValue=item.title;
      if(item.title=="Canceled"){
      let respData = await UsbService.getShippingData("get", this.userId,2);
      respData?.forEach((element) => {
        element.createdate = Utility.convertESTToLocal(element.createdate)});
      this.bulkListDataList = respData;
    }
      else if(item.title=="Complete"){
      let respData = await UsbService.getShippingData("get", this.userId,1);    
      respData?.forEach((element) => {
        element.createdate = Utility.convertESTToLocal(element.createdate)});
      this.bulkListDataList = respData;
      }
      else{
        let busObj = this.bulkListData.filter((x) => x.so_lot_status == item.title);
        this.bulkListDataList = busObj;
      }
      
      this.totalRecords = this.bulkListDataList.length;
    },
    clearFilter() {
      this.bulkListDataList = this.bulkListData;
      this.totalRecords = this.bulkListDataList.length;
      this.status = "";
      this.filterValue=""
      this.getBulkData()
    },
    closeDialog() {
      this.bulkOrderDialog = false;
      this.orderDetailsArray = [];
      this.detailsTableData = [];
      this.trackNum = "";
    },
    fileName() {
      this.excelName =
        "Bulk_Shipping_Report" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".xls";
    },
    async onClickAction(id, loc) {
      this.soLotId = id;
      this.bulkOrderDialog = true;
      let obj = {
        user_key: this.userId,
        so_lot_key: id,
      };
      let oderDetails = await UsbService.postShippingHeader("post", obj);
      let memberDetails = await UsbService.postShippingDetailsMember("post", obj);
      this.detailsTableData = memberDetails;
      this.detailsTableData.forEach((element) => {
        element.created_date = Utility.convertESTToLocal(element.created_date);
      });
      if (loc == "viewRtsDetails") {
        this.showTrackingSection = true;
      }
      this.orderDetailsArray = [
        { label: "Lot Number", value: oderDetails[0].lotno },
        { label: "Project Name", value: oderDetails[0]?.Project_Name },
        { label: "Project Number", value: oderDetails[0]?.Project_Number },
        { label: "Request Number", value: oderDetails[0]?.req_number },
        { label: "Is Denovo?", value: oderDetails[0]?.Is_Denovo },
        { label: "SO Lot Status", value: oderDetails[0]?.so_lot_status },
        { label: "RITM", value: oderDetails[0]?.ritm_count },
        { label: "Created", value: Utility.convertESTToLocal(oderDetails[0]?.created) },
      ];
    },
    async onClickSubmitTrack(trackNum) {
      if (this.$refs.trackingForm.validate()) {
        let obj = {
          so_lot_key: this.soLotId,
          waybill: trackNum,
          user_key: this.userId,
        };
        let res = await UsbService.postTrackingNum("post", obj, true);
        if (res.message == "Updated") {
          this.closeDialog();  
          this.getDashboardData();
          this.getBulkData();
        }
      }
    },
  },
  components: {
    breadcrumbComp,
    DisplayTextData,
  },
};

import { render, staticRenderFns } from "./bulk-shipping.vue?vue&type=template&id=7d6310f9&scoped=true&"
import script from "./js/bulk-shipping.js?vue&type=script&lang=js&"
export * from "./js/bulk-shipping.js?vue&type=script&lang=js&"
import style0 from "./bulk-shipping.vue?vue&type=style&index=0&id=7d6310f9&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d6310f9",
  null
  
)

export default component.exports